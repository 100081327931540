
import { defineComponent } from "vue";
import { required } from "@vuelidate/validators";
import { useVuelidate } from "@vuelidate/core";
import { useStore } from "vuex";
import CheckBox from "@/components/common/CheckBox.vue";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import TextButton from "@/components/common/TextButton.vue";
import CloseIcon from "icons/Close.vue";

export default defineComponent({
  name: "WriteComment",
  setup: () => ({ v$: useVuelidate() }),
  components: { CheckBox, BaseImgCircle, TextButton, CloseIcon },
  props: {
    isSecret: {
      type: Boolean,
      default: false,
    },
    mentionedNickname: {
      type: String,
      default: "",
    },
    comment: String,
    postType: String,
    postCategory: String,
  },
  emits: [
    "update:comment",
    "update:isSecret",
    "clearMentionedNickname",
    "onSubmit",
  ],
  data() {
    const store = useStore();
    return {
      store,
      secret: false,
      thumbnail: "",
    };
  },
  validations: {
    comment: { required },
  },
  watch: {
    mentionedNickname(newValue, oldValue) {
      if (newValue && newValue != oldValue) {
        this.focousInput();
      }
    },
  },
  computed: {
    isLogin: function () {
      return this.store.getters["member/isLogin"];
    },
    isMentioned() {
      if (this.mentionedNickname) {
        return "@" + this.mentionedNickname + " 댓글 달기";
      }
      return "댓글 달기";
    },
  },
  mounted() {
    let user = this.store.getters["member/member"];
    this.thumbnail = user?.thumbnail;
  },
  methods: {
    submit() {
      this.$emit("onSubmit");
    },
    close() {
      this.$emit("update:comment", "");
      this.$emit("clearMentionedNickname");
    },
    checkLogin() {
      const params = {
        type: this.postType,
        reply_secret: this.secret ? "true" : "false",
        announcement_category:
          this.postType == "talkroom"
            ? this.postCategory === "배우"
              ? "actor"
              : "staff"
            : undefined,
        talkroom_category: this.postCategory,
      };
      if (this.postType == "urgent") {
        params.talkroom_category = undefined;
      }
      this.$pushGAEvent("reply_try", params);
      if (!this.isLogin) {
        this.$flutter.callHandler("showToast", {
          message: "로그인 사용자만 작성할 수 있습니다.",
        });
      } else {
        this.focousInput();
      }
    },
    toggleSecret(e: InputEvent) {
      this.$emit(
        "update:isSecret",
        (e.target as HTMLInputElement).checked ? true : false
      );
      this.focousInput();
    },
    focousInput() {
      (this.$refs.inputComment as HTMLInputElement).focus();
    },
  },
  beforeMount() {
    this.$flutter.callHandler("hideGnb");
  },
  beforeUnmount() {
    this.$flutter.callHandler("showGnb");
  },
});
