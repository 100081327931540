
import { defineComponent } from "vue";

export default defineComponent({
  name: "PostTimestamp",
  props: {
    tstamp: {
      type: String,
      required: true,
    },
  },
  computed: {
    tstampFormat(): string {
      return this.$timeAgo(this.$convertToDate(this.tstamp));
    },
  },
});
