
import { defineComponent } from "vue";

export default defineComponent({
  name: "PostOwnerNickname",
  props: {
    nickname: {
      type: String,
      required: true,
      default: "",
    },
  },
});
