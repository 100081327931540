
import { defineComponent } from "vue";
import type { Post } from "./post";
import BaseImgCircle from "@/components/BaseImgCircle.vue";
import PostOwnerNickname from "./PostOwnerNickname.vue";
import PostTimestamp from "./PostTimestamp.vue";
import PostContent from "./PostContent.vue";

export default defineComponent({
  name: "CommonPostDetail",
  components: {
    BaseImgCircle,
    PostOwnerNickname,
    PostTimestamp,
    PostContent,
  },
  props: {
    post: {
      type: Object as () => Post,
      required: true,
    },
  },
});
