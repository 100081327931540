import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, withCtx as _withCtx, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-55901c49"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "form-wrap" }
const _hoisted_2 = {
  key: 0,
  class: "msg-status"
}
const _hoisted_3 = { class: "msg-status-text" }
const _hoisted_4 = { class: "form" }
const _hoisted_5 = { class: "secret-wrap" }
const _hoisted_6 = { class: "comment" }
const _hoisted_7 = { class: "input-wrap" }
const _hoisted_8 = ["value", "placeholder", "readonly"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_CheckBox = _resolveComponent("CheckBox")!
  const _component_BaseImgCircle = _resolveComponent("BaseImgCircle")!
  const _component_TextButton = _resolveComponent("TextButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.mentionedNickname)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.mentionedNickname) + "님에게 댓글 남기는중 ", 1),
          _createElementVNode("button", {
            onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)))
          }, [
            _createVNode(_component_CloseIcon, {
              class: "icon-close",
              size: 18
            })
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_CheckBox, {
          modelValue: _ctx.secret,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.secret) = $event)),
          onClick: _withModifiers(_ctx.toggleSecret, ["stop"]),
          isCheckIcon: false
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", {
              class: _normalizeClass(["secret", _ctx.secret ? 'active' : ''])
            }, " 비밀 댓글 달기 ", 2)
          ]),
          _: 1
        }, 8, ["modelValue", "onClick"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createVNode(_component_BaseImgCircle, {
          size: "40px",
          src: 
            _ctx.thumbnail
              ? _ctx.thumbnail
              : require('@/assets/images/account_default.png')
          
        }, null, 8, ["src"]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("input", {
            ref: "inputComment",
            type: "text",
            value: _ctx.comment,
            onInput: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('update:comment', $event.target.value))),
            placeholder: _ctx.isMentioned,
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.checkLogin && _ctx.checkLogin(...args))),
            readonly: !_ctx.isLogin
          }, null, 40, _hoisted_8),
          _createVNode(_component_TextButton, {
            class: "btn-submit",
            onOnClick: _ctx.submit,
            disabled: _ctx.v$.$invalid || !_ctx.isLogin,
            fontSize: "10px",
            lineHeight: "12px"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" 게시 ")
            ]),
            _: 1
          }, 8, ["onOnClick", "disabled"])
        ])
      ])
    ])
  ]))
}